<template>
    <div class="assignStep-container">
      <el-steps :active="stepActive" align-center>
        <el-step title="店铺信息填报" @click.native="() => { stepActive > 1 ? routeFun(`/assign-order/assign-list/assign-one?id=${$route.query.id}`):'' }" icon="el-icon-s-help"></el-step>
        <el-step title="供应商信息填报" @click.native="() => { stepActive > 2 ? routeFun(`/assign-order/assign-list/assign-two?id=${$route.query.id}`): '' }" icon="el-icon-s-help"></el-step>
        <el-step title="生成订单" icon="el-icon-s-help"></el-step>
      </el-steps>
    </div>
</template>
<script>
export default {
    props: ['stepActive'],
    methods: {
        routeFun (url) {
            this.$router.push(url)
        },
    }
}
</script>
<style lang="less" scoped>
.assignStep-container {
    padding: 46px 0 42px;
    text-align: left;
    /deep/.el-step__head.is-process, /deep/.el-step__title.is-process {
        color: #999;
        font-weight: 400;
    }
    /deep/.el-step__head.is-wait, /deep/.el-step__title.is-wait {
        color: #999;
    }
    /deep/.el-step__head.is-finish, /deep/.el-step__title.is-finish {
        color: #1890ff;
        cursor: pointer;
    }
}
</style>