import fetch from "./xhr/index";

/**
 * 派单列表
 * @param
 */
export const assignOrder = param => fetch("POST", "/cms/orderDistribute/selectOrderDistribute", param);

/**
 * 派单店铺列表
 * @param
 */
export const assignShopSelect = param => fetch("GET", "/cms/orderDistribute/selectShopByName", param);

/**
 * 派单店铺子账号
 * @param
 */
export const assignShopSub = param => fetch("GET", "/cms/orderDistribute/selectShopById", param);

/**
 * 保存派单的订单
 * @param
 */
export const saveAssignOrder = param => fetch("POST", "/cms/orderDistribute/insertOrderDistribute", param);

/**
 * 派单上传附件接口
 * @parama
 */
export const assignOrderUpload = param => fetch("POST", "/cms/orderDistribute/fileUpload", param);

/**
 * 派单删除附件接口
 * @parama
 */
export const assignOrderDelete = param => fetch("POST", "/cms/orderDistribute/fileDelete", param);

/**
 * 派单详情接口
 * @parama
 */
export const assignOrderDetail = param => fetch("GET", "/cms/orderDistribute/selectDistributeDetail", param);

/**
 * 派单详情--工作日志
 * @parama
 */
export const orderWorkLog = param => fetch("GET", "/cms/orderDistribute/getLogList", param);

/**
 * 派单详情--添加工作日志
 * @parama
 */
export const addWorkLog = param => fetch("POST", "/cms/orderDistribute/addWorkLog", param);

/**
 * 接单中心-接收/拒收
 * @parama
 */
export const receiveOrder = param => fetch("GET", "/cms/orderDistribute/receptionDistribute", param);

/**
 * 接单中心-头部汇总
 * @parama
 */
 export const orderSumList = param => fetch("GET", "/cms/orderDistribute/getReportList", param);

/**
 * 派单中心-发起结算
 * @parama
 */
 export const settleCommit = param => fetch("POST", "/cms/orderDistribute/insertSettle", param);

/**
 * 派单中心-结算作废
 * @parama
 */
 export const voidSettle = param => fetch("GET", "/cms/orderDistribute/settleCancellation", param);

/**
 * 派单中心-根据id查看结算详情
 * @parama
 */
 export const settleIdDetail = param => fetch("GET", "/cms/orderDistribute/selectSettleDetailById", param);
 